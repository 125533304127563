import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import routers from './router/router.js'
import iView from 'view-design'
import VueClipboard from 'vue-clipboard2'
import VueLoading from 'vue-loading-template'
import 'view-design/dist/styles/iview.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(iView)
Vue.use(VueLoading)
Vue.use(VueClipboard)
Vue.use(ElementUI)

const router = new VueRouter({
	mode: 'history',
	routes: routers
})
//
// //挂载路由导航守卫
// router.beforeEach((to, from, next) => {
// 	//to 将要访问的路径
// 	//from 代表从那个路径跳转而来
// 	//next 是一个函数，放行
// 	//    next() 放行     next('/login) 强制跳转
//
// 	//如果用户访问的是登录页面，则直接放行
// 	if (to.path === '/login') {
// 		next()
// 	} else {
// 		//从 sessionStorage 中获取 保存的 token
// 		const tokenStr = window.sessionStorage.getItem('token')
// 		//如果没有token，强制跳转到登录页面，如果有，则直接放行
// 		if (!tokenStr) {
// 			next('/login')
// 		} else {
// 			next()
// 		}
// 	}
// })

new Vue({
	render: h => h(App),
	router: router,
}).$mount('#app')
