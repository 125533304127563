const routers = [{
		path: '/',
		redirect: '/login',
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login.vue')
	},
	{
		path: '/menu',
		name: 'menu',
		component: () => import('@/views/menu.vue')
	},
	{
		path: '*',
		component: () => import('@/views/login.vue')
	}
]

export default routers;
